import React, {useEffect, useState} from "react"
import _ from "lodash"
import qs from "qs"
import {AppSync} from "../../libraries/appsync"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import ProductList from "../../components/ProductList"
import {ButtonTop} from "../../components/Button"
import PageNavigation from "../../components/PageNavigation"
import PageTitle from "../../components/PageTitle"
import Pagination from "../../components/Pagination"
import GroupSelect from "../../components/GroupSelect"
import {Preview, cryptString} from "../../libraries/preview";

export default ({location}) => {
  const initialPage = 1
  const pageSize = 16

  const [loading, setLoading] = useState(true)
  const [products, setProducts] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [categories, setCategories] = useState({initial: "", c1: {}, c2: {}})
  const [categoryText, setCategoryText] = useState("")
  const [currentPage, setCurrentPage] = useState(initialPage)

  const pageChangeHandler = p => {
    setCurrentPage(p)
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior:'smooth'
      });
    }, 10)
  }

  let originalProducts = []

  useEffect(() => {
    setLoading(true)

    Preview(cryptString(location)).then(customDateTime => {
      const productQuery = customDateTime ? `(preview:"${customDateTime}")` : ""

      AppSync.post("/", {
        query: `
          query products {
            listProducts${productQuery} {
              Hin
              Title
              Release
              OrderDateFrom
              OrderDateTo
              Price
              NetPrice
              Status
              PreType
              PreFromDate
              PreToDate
              ecCategory {
                member
                category1
                category2
              }
              ecImage
              ecVisibleBeginDate
              ecReleaseDate
              ecSellingStatus
              ecSort
            }
            listGroups {
              items {
                hinban
                parent
                child
                groupName
              }
            }
            listCategories {
              value {
                category1_name
                category2_name
                category1 {
                  categoryKey
                  categoryName
                  sort
                }
                category2 {
                  categoryKey
                  categoryName
                  sort
                }
              }
            }
          }
        `
      }).then(res => {
        // ソート順:降順、公開日時:降順、品番:昇順
        const products = _.orderBy(
          _.get(res.data, "data.listProducts"),
          [
            s => s.ecSort === "11111111111" ? 0 : parseInt(s.ecSort),
            v => v.ecVisibleBeginDate,
            h => h.Hin,
          ],
          ["desc", "desc", "asc"]
        )
        const children = _.map(_.filter(_.get(res.data, "data.listGroups.items"), g => g.parent != null && g.child == null), p => p.hinban)
        const parentProducts = _.filter(products, p => children.indexOf(p.Hin) === -1)
        // 紐づく商品グループがある場合は取得挿入
        const groups = []
        _.get(res.data, "data.listGroups.items", []).forEach(group => {
          groups[group.hinban] = group.groupName
        })
        for (const productIndex in products) {
          products[productIndex]["groupName"] = _.get(groups, products[productIndex]["Hin"], undefined)
        }

        // カテゴリタイトル
        const c1Title = _.get(res.data, "data.listCategories.value.category1_name")
        const c2Title = _.get(res.data, "data.listCategories.value.category2_name")

        // カテゴリリスト ソート順:降順
        const c1List = _.orderBy(
          _.get(res.data, "data.listCategories.value.category1"),
          [c => c.sort],
          ["desc"]
        )
        const c2List = _.orderBy(
          _.get(res.data, "data.listCategories.value.category2"),
          [c => c.sort],
          ["desc"]
        )

        const queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true})
        const initialCategory = _.get(queryString, "c", "")

        setCategories({
          initial: initialCategory,
          c1Title: c1Title,
          c2Title: c2Title,
          c1List: c1List,
          c2List: c2List,
          c1Value: window.sessionStorage.getItem("C1Value"),
          c2Value: window.sessionStorage.getItem("C2Value"),
          callback: groupSelectCallback
        })

        originalProducts = parentProducts

        // 全カテゴリ保持用
        setAllProducts(products)
        if (initialCategory.length > 0) {
          const c1Found = c1List.find(c => c.categoryKey === initialCategory)
          const c2Found = c2List.find(c => c.categoryKey === initialCategory)
          if (c1Found) {
            window.sessionStorage.setItem("C1Value", c1Found.categoryKey)
            window.sessionStorage.setItem("C2Value", "")
          }
          if (c2Found) {
            window.sessionStorage.setItem("C1Value", "")
            window.sessionStorage.setItem("C2Value", c2Found.categoryKey)
          }

          groupSelectCallback({
            c1: c1Found ? {key: c1Found.categoryKey, name: c1Found.categoryName} : {key: "", name: ""},
            c2: c2Found ? {key: c2Found.categoryKey, name: c2Found.categoryName} : {key: "", name: ""}
          }, originalProducts)
        } else if (window.sessionStorage.getItem("C1Value") || window.sessionStorage.getItem("C2Value")) {
          const c1Found = c1List.find(c => c.categoryKey === window.sessionStorage.getItem("C1Value"))
          const c2Found = c2List.find(c => c.categoryKey === window.sessionStorage.getItem("C2Value"))
          groupSelectCallback({
            c1: c1Found ? {key: c1Found.categoryKey, name: c1Found.categoryName} : {key: "", name: ""},
            c2: c2Found ? {key: c2Found.categoryKey, name: c2Found.categoryName} : {key: "", name: ""}
          }, originalProducts)
      
        } else {
          setProducts(parentProducts)
        }

        setLoading(false)
      })
    })
  }, [])

  const groupSelectCallback = (groups, products) => {
    if (products === undefined) {
      products = originalProducts
    }

    // カテゴリ未選択
    if (groups["c1"].key.length === 0 && groups["c2"].key.length === 0) {
      setProducts(products)
      setCategoryText("")
      setCurrentPage(1)
      return
    }

    // カテゴリが1つ以上選択されている
    setProducts(_.filter(products, p => {
      let hit1 = false
      let hit2 = false
      let text = ["", ""]

      if (groups["c1"].key.length === 0) {
        hit1 = true
      } else {
        text[0] = groups["c1"].name
        const c = JSON.parse(_.get(p, "ecCategory.category1", ""))
        if (_.get(c, "categoryKey.S") === groups["c1"].key) {
          hit1 = true
        }
      }

      if (groups["c2"].key.length === 0) {
        hit2 = true
      } else {
        text[1] = groups["c2"].name
        const c = JSON.parse(_.get(p, "ecCategory.category2", ""))
        if (_.get(c, "categoryKey.S") === groups["c2"].key) {
          hit2 = true
        }
      }

      const textTrim = _.trim(text.join(" / "), "/ ")
      setCategoryText(textTrim.length === 0 ? "" : `${textTrim} の検索結果`)
      setCurrentPage(1)

      return hit1 && hit2
    }))
  }

  return (
    <Layout>
      <SEO title="ALL"/>
      <section className="constraint">
        <div className="group-select-wrap">
          <PageTitle>Items</PageTitle>
          <GroupSelect group={categories} products={products} allProducts={allProducts}/>
        </div>
        <p className="group-text">{categoryText}</p>
        <ProductList items={products.slice((currentPage - 1) * pageSize, currentPage * pageSize)} loading={loading}/>
        <Pagination items={products} initialPage={initialPage} pageSize={pageSize} changeHandler={pageChangeHandler}/>
        <PageNavigation>
          <ButtonTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}