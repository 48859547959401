import React from "react"
import _ from "lodash"
import ProductCard from "../ProductCard";
import classNames from "classnames";
import "./productlist.css"

export default ({items, loading}) => {
  //TODO: paging

  return (
    <div className={classNames({loading: loading})}>
      {!_.isEmpty(items) ? (
        <div className="item-list">
          {_.map(items, item => <ProductCard item={item} key={item.Hin}/>)}
        </div>
      ) : (
        loading === false && <p style={{
          padding: "2em 0",
          textAlign: "center",
        }}>商品はございません。</p>
      )}
    </div>
  )
}