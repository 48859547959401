// @see https://www.digitalocean.com/community/tutorials/how-to-build-custom-pagination-with-react

import React, {useEffect, useState} from "react"
import _ from "lodash"

export default ({items, initialPage = 1, pageSize = 5, changeHandler}) => {
  const itemTotal = _.size(items)
  const totalPages = Math.ceil(itemTotal / pageSize)
  const pageNeighbours = 1

  const LEFT_PAGE = 'LEFT';
  const RIGHT_PAGE = 'RIGHT';

  const [currentPage, setCurrentPage] = useState(initialPage)

  useEffect(() => setCurrentPage(1), [items])

  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  }

  const fetchPageNumbers = () => {
    const totalNumbers = (pageNeighbours * 2) + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = (totalPages - endPage) > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case (hasLeftSpill && !hasRightSpill): {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case (!hasLeftSpill && hasRightSpill): {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case (hasLeftSpill && hasRightSpill):
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];

    }

    return range(1, totalPages);
  }

  const gotoPage = page => {
    const p = Math.max(0, Math.min(page, totalPages));
    setCurrentPage(p)
    changeHandler(p)
  }

  const handleClick = page => evt => {
    evt.preventDefault()
    gotoPage(page);
  }

  const handleMoveLeft = evt => {
    evt.preventDefault()
    gotoPage(currentPage - (pageNeighbours * 2) - 1)
  }

  const handleMoveRight = evt => {
    evt.preventDefault();
    gotoPage(currentPage + (pageNeighbours * 2) + 1)
  }

  const pages = fetchPageNumbers();

  return (
    <div className="pagination">
      {pages.map((page, index) => {

        if (page === LEFT_PAGE) return (
          <button type="button" key={index} aria-label="Previous" onClick={handleMoveLeft}>
            &laquo;
          </button>
        )

        if (page === RIGHT_PAGE) return (
          <button type="button" key={index} aria-label="Next" onClick={handleMoveRight}>
            &raquo;
          </button>
        )

        return (
          <button type="button" key={index} className={currentPage === page ? "active" : ""} onClick={handleClick(page)}>
            {page}
          </button>
        )

      })}
    </div>
  )
}