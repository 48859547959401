import React from "react"
import {Link} from "gatsby"
import _ from "lodash"
import {CategoryMember} from "../ProductCategory"
import {ImgProduct} from "../Image"
import ProductPrice from "../ProductPrice"
import style from "./productcard.module.css"
import ProductTag from "../ProductTag";

export default ({item}) => (
  <div className={style.card}>
    <Link to={`/product/detail/?id=${item.Hin}`}>
      <div><ImgProduct item={item}/></div>
      <CategoryMember memberList={_.get(item, "ecCategory.member")}/>
      <div style={{padding: "0 8px"}}>
        <ProductTag tagList={{sellingStatus: _.get(item, "ecSellingStatus")}}/>
      </div>
      <h2>{_.get(item, "groupName", item.Title)}</h2>
      <ProductPrice price={_.get(item, "NetPrice")} style={{
        padding: "0 12px 16px 0",
        fontSize: "14px",
      }}/>
    </Link>
  </div>

)